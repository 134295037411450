import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MobileMenu from "./MobileMenu";
import MobileMenuItem from "./MobileMenuItem";
import { Link } from "react-router-dom";
import HideOnScroll from "./HideOnScroll";
import ProfileMenu from "./UserMenu";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    responsiveNav: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    donateButton: {
      backgroundColor: theme.palette.secondary.contrastText,
    },
  })
);

export default function Navbar() {
  const classes = useStyles();

  let menuItems: MobileMenuItem[] = [];
  menuItems.push({ name: "Home", link: "/" });

  return (
    <div className={classes.root}>
      <HideOnScroll>
        <AppBar position="static">
          <Toolbar id="back-to-top-anchor">
            <MobileMenu className={classes.menuButton} menuItems={menuItems} />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link
                  to={menuItems[0].link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button
                    className={[classes.responsiveNav].join(" ")}
                    color="inherit"
                  >
                    {menuItems[0].name}
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <ProfileMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
}
