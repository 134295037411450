import "mobx-react-lite/batchingForReactDom";
import Account from "../Domain/Account";
import { DynamoDBClient } from "../Clients/DynamoDBClient";
import { action, observable } from "mobx";

export interface IAccountStore {}

export default class AccountStore implements IAccountStore {
  private dbClient: DynamoDBClient = new DynamoDBClient(
    "https://pidqxp680j.execute-api.us-east-1.amazonaws.com/v1/"
  );

  @observable public allAccountsForFamily: Account[] = [];

  @action
  public async getAccountsByFamilyId(familyId: string) {
    this.allAccountsForFamily = [] as Account[];
    await this.dbClient
      .getAllAccountsForFamily(familyId)
      .then((accounts) =>
        accounts.forEach((account) => this.allAccountsForFamily.push(account))
      );
    return this.allAccountsForFamily;
  }

  @action
  public async addPlaidAccounts(accounts: Account[]) {
    await this.dbClient.addAccounts(accounts);
    this.allAccountsForFamily = [] as Account[];
    this.getAccountsByFamilyId(accounts[0].familyId);
  }

  @action
  public async changeConnectionForAccount(
    accountId: string,
    isConnected: boolean,
    familyId: string
  ) {
    await this.dbClient.setIsConnectedForAccount(accountId, isConnected);
    this.allAccountsForFamily = [] as Account[];
    this.getAccountsByFamilyId(familyId);
  }

  @action
  public async deleteAccountsByFamily(familyId: string) {
    await this.dbClient.deleteAccountsForFamily(familyId);
    this.allAccountsForFamily = [] as Account[];
  }
}
