import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import React, { Component } from "react";
import Transaction from "../Domain/Transaction";
import Category from "../Domain/Category";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    width: "150%",
  },
  justForTesting: {
    border: "1px solid green",
  },
});

export interface IEditTransaction {
  categories: Category[];
  initialTransaction: Transaction;
  editTransactionFunction: (transaction: Transaction) => void;
  classes: any;
}

class EditTransaction extends Component<IEditTransaction> {
  state = {
    editingTransaction: {
      ...this.props.initialTransaction,
    },
  };

  formatDate = (date: Date | undefined) => {
    if (date !== undefined) {
      date = new Date(date);
      var month = (date.getMonth() + 1).toString();
      var day = date.getDate().toString();
      var year = date.getFullYear().toString();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      var returnvalue = [year, month, day].join("-");
      return returnvalue;
    }
  };

  inputChange = (event: any) => {
    this.setState({
      editingTransaction: {
        ...this.state.editingTransaction,
        [event.target.name]: event.target.value,
      } as Transaction,
    });
  };

  inputDateChange = (event: any) => {
    var dateString = event.target.value;
    dateString = dateString.concat(", 00:00:01");
    this.setState({
      editingTransaction: {
        ...this.state.editingTransaction,
        date: new Date(dateString),
      } as Transaction,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Paper
          tabIndex={-1}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={this.props.classes.paper}
        >
          <h2>Edit Transaction</h2>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <TextField
                name="description"
                label="Description"
                id="description"
                margin="dense"
                variant="outlined"
                value={this.state.editingTransaction.description}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item>
              <TextField
                name="amount"
                label="Amount"
                id="amount"
                margin="dense"
                variant="outlined"
                value={this.state.editingTransaction.amount}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item>
              <TextField
                type="date"
                name="date"
                id="date"
                value={this.formatDate(this.state.editingTransaction.date)}
                onChange={this.inputDateChange}
                variant="outlined"
                label="Date"
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
              />
            </Grid>
            <Grid item>
              <InputLabel id="categoryId">Category</InputLabel>
              <Select
                labelId="categoryId"
                id="categorySelectId"
                name="categoryId"
                value={this.state.editingTransaction.categoryId}
                onChange={this.inputChange}
                className={this.props.classes.selectBox}
              >
                {this.props.categories.map((category, i) => {
                  return (
                    <MenuItem key={"option" + i} value={category.Id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid
                  item
                  xs={12}
                  className={this.props.classes.justForTesting}
                >
                  <Button
                    onClick={() =>
                      this.props.editTransactionFunction(
                        this.state.editingTransaction
                      )
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(EditTransaction);
