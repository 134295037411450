import AWS from "aws-sdk";

export class CognitoClient {
  updateUserFamilyId(familyId: string, email: string) {
    var cognitoProvider = new AWS.CognitoIdentityServiceProvider();
    const updateParams = {
      UserAttributes: [
        {
          Name: "custom:familyid",
          Value: familyId,
        },
      ],
      UserPoolId: "us-east-1_9b9tqobtU",
      Username: email,
    };
    cognitoProvider.adminUpdateUserAttributes(updateParams).promise();
  }
}
