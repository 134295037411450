import User from "../Domain/User";
import { observable, action } from "mobx";
import { Auth } from "aws-amplify";
import "mobx-react-lite/batchingForReactDom";

export interface IAuthenticationStore {
  getAuthenticatedUser: () => Promise<User | undefined>;
  signOutUser: () => Promise<void>;
}

export default class AuthenticationStore implements IAuthenticationStore {
  @observable public currentUser: User | undefined;
  @action
  public async getAuthenticatedUser() {
    await Auth.currentAuthenticatedUser().then(async (user: any) => {
      if (user) {
        this.currentUser = new User();
        this.currentUser.id = user.attributes.sub;
        this.currentUser.emailAddress = user.attributes.email;
        this.currentUser.idToken = user.signInUserSession.idToken.jwtToken;
        this.currentUser.accessToken =
          user.signInUserSession.accessToken.jwtToken;

        await Auth.userAttributes(user).then(async (attributes: any) => {
          const familyIdAttribute = attributes.find(
            (attribute: any) => attribute.getName() === "custom:familyid"
          );
          if (familyIdAttribute) {
            this.currentUser!.familyId = familyIdAttribute.getValue();
          }
        });
      }
    });
    return this.currentUser;
  }

  public async signOutUser() {
    this.currentUser = undefined;
    return Auth.signOut();
  }
}
