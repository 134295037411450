import React, { ReactNode } from "react";
import { Paper } from "@material-ui/core";
export interface ITransactionGroup {
  children: ReactNode;
  index: string;
  expanded: string | false;
}

const TransactionGroup: React.FC<ITransactionGroup> = (props) => {
  return (
    <React.Fragment>
      {props.expanded === "expandedSection" + props.index && (
        <Paper key={"expandedKey" + props.index}>{props.children}</Paper>
      )}
    </React.Fragment>
  );
};

export default TransactionGroup;
