import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useState } from "react";
import { Grid, Box, Modal } from "@material-ui/core";
import AddTransaction from "./AddTransaction";
import Category from "../Domain/Category";
import Transaction from "../Domain/Transaction";
import NumberFormat from "react-number-format";

export interface ICategoryHeader {
  currentCategory: Category;
  categories: Category[];
  addTransactionClick: (transaction: Transaction) => void;
  expandTransactions: (index: number) => void;
  index: number;
}

const CategoryHeader: React.FC<ICategoryHeader> = (props) => {
  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);
  const addTransactionWithCloseModal = (transaction: Transaction) => {
    props.addTransactionClick(transaction);
    setShowNewTransactionModal(false);
  };
  const calculateAmountLeft = (amount: number, transactions: Transaction[]) => {
    var currentAmount = Number(amount);

    transactions.forEach((transaction) => {
      currentAmount = currentAmount - transaction.amount;
    });
    return Number(currentAmount.toFixed(2));
  };
  return (
    <React.Fragment>
      <Modal
        open={showNewTransactionModal}
        onClose={() => {
          setShowNewTransactionModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AddTransaction
          categories={props.categories}
          defaultCategoryId={props.currentCategory.Id}
          addTransaction={(transaction) =>
            addTransactionWithCloseModal(transaction)
          }
        />
      </Modal>
      <Box fontWeight="fontWeightBold">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={8}
            xl={10}
            onClick={() => props.expandTransactions(props.index)}
          >
            {props.currentCategory.name}
          </Grid>
          <Grid item xs={4} xl={2}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <NumberFormat
                  value={calculateAmountLeft(
                    props.currentCategory.amount,
                    props.currentCategory.transactions
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
              </Grid>
              <Grid item>
                <AddCircleIcon
                  onClick={() => setShowNewTransactionModal(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default CategoryHeader;
