import { withStyles } from "@material-ui/core/styles";
import { Paper, TextField, Grid, Button } from "@material-ui/core";
import React, { Component } from "react";
import Category from "../Domain/Category";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    width: 400,
  },
  justForTesting: {
    border: "1px solid green",
  },
});

export interface IAddCategory {
  addCategory: (category: Category) => void;
  classes: any;
}

class AddCategory extends Component<IAddCategory> {
  state = {
    newCategory: {
      ...new Category(),
    },
  };

  inputChange = (event: any) => {
    this.setState({
      newCategory: {
        ...this.state.newCategory,
        [event.target.name]: event.target.value,
      } as Category,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Paper
          tabIndex={-1}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={this.props.classes.paper}
        >
          <h2>Add Category</h2>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <TextField
                name="name"
                label="Name"
                id="description"
                margin="dense"
                variant="outlined"
                value={this.state.newCategory.name}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item>
              <TextField
                name="amount"
                label="Amount"
                id="amount"
                margin="dense"
                variant="outlined"
                value={this.state.newCategory.amount}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Button
                    onClick={() =>
                      this.props.addCategory(this.state.newCategory as Category)
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(AddCategory);
