import React, { ReactNode, useState } from "react";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";

export interface IAuthenticationProps {
  store?: MasterStore;
  children: ReactNode;
}

const Authentication: React.FC<IAuthenticationProps> = (props) => {
  const [authState, setAuthState] = useState("");

  props
    .store!.authenticationStore!.getAuthenticatedUser()
    .then((user) => {
      if (user) {
        setAuthState("signedin");
      }
    })
    .catch((error) => {
      //do nothing
    });

  React.useEffect(() => {
    return onAuthUIStateChange((newAuthState: any) => {
      setAuthState(newAuthState);
    });
  }, []);

  return (
    <div>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn usernameAlias="email" slot="sign-in" />
        <AmplifySignUp
          usernameAlias="email"
          slot="sign-up"
          formFields={[{ type: "email" }, { type: "password" }]}
        ></AmplifySignUp>
        {authState === "signedin" && <div>{props.children}</div>}
      </AmplifyAuthenticator>
    </div>
  );
};

export default inject("store")(observer(Authentication));
