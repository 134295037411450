import { observable, action, computed, toJS } from "mobx";
import { DynamoDBClient } from "../Clients/DynamoDBClient";
import "mobx-react-lite/batchingForReactDom";
import Budget from "../Domain/Budget";
import Category from "../Domain/Category";
import { Guid } from "guid-typescript";
import Transaction from "../Domain/Transaction";
import CategoryGroup from "../Domain/CategoryGroup";

export default class BudgetStore {
  @observable public allBudgetsForUser: Budget[] = [];
  @observable private currentBudgetId = "";
  private dbClient: DynamoDBClient = new DynamoDBClient(
    "https://pidqxp680j.execute-api.us-east-1.amazonaws.com/v1/"
  );

  @computed
  public get currentBudgetHasTransactions() {
    var returnValue = false;
    if (this.currentBudget) {
      this.currentBudget.categories.forEach((cat) => {
        if (cat.transactions.length > 0) {
          returnValue = true;
        }
      });
    }

    return returnValue;
  }

  @computed
  public get currentBudgetCategorySums() {
    var sums = 0;
    if (this.currentBudget) {
      this.currentBudget.categories.forEach((category) => {
        sums = sums + +category.amount;
      });
    }
    return sums;
  }

  @computed
  public get currentBudgetTransactionSums() {
    var sums = 0;
    if (this.currentBudget) {
      this.currentBudget.categories.forEach((category) => {
        category.transactions.forEach((transaction) => {
          sums = sums + +transaction.amount!;
        });
      });
    }
    return sums;
  }

  @computed
  public get currentBudget(): Budget {
    var currentBudget = new Budget();
    if (this.currentBudgetId !== "") {
      for (const budget of this.allBudgetsForUser) {
        if (budget.Id === this.currentBudgetId) {
          currentBudget = budget;
        }
      }
    }
    return currentBudget;
  }

  @computed
  private get indexOfCurrentBudget() {
    var indexOfCurrent = 0;

    if (this.currentBudgetId !== "")
      indexOfCurrent = this.allBudgetsForUser.findIndex(
        (budget) => budget.Id === this.currentBudgetId
      );

    return indexOfCurrent;
  }

  @computed
  public get hasNextBudget() {
    if (this.indexOfCurrentBudget === 0) {
      return false;
    }
    return true;
  }

  @computed
  public get hasPreviousBudget() {
    if (this.indexOfCurrentBudget === this.allBudgetsForUser.length - 1) {
      return false;
    }
    return true;
  }

  public async getUnprocessedTransactions(familyId: string) {
    var transactions = await this.dbClient.getUnprocessedTransactions(familyId);
    return transactions;
  }

  public async updateUnprocessedTransaction(
    transactionId: string,
    categoryId: string,
    ignore: boolean
  ) {
    await this.dbClient.updateUnprocessedTransaction(
      transactionId,
      categoryId,
      ignore
    );
    var id = this.currentBudget.familyId.toString();
    sessionStorage.clear();
    this.getBudgetsByFamilyId(id);
  }

  public getBudgetCategoriesSum(budgetId: string) {
    const summingBudget = this.allBudgetsForUser.find(
      (budge) => budge.Id === budgetId
    );
    let sum = 0;
    if (summingBudget) {
      summingBudget.categories.forEach((cat) => {
        sum = sum + +cat.amount;
      });
    }
    return sum;
  }

  public getBudgetAmountLeft(budgetId: string) {
    const fullBudgetSum = this.getBudgetCategoriesSum(budgetId);
    const summingBudget = this.allBudgetsForUser.find(
      (budge) => budge.Id === budgetId
    );
    let transactionSum = 0;
    if (summingBudget) {
      summingBudget.categories.forEach((cat) => {
        cat.transactions.forEach((tran) => {
          transactionSum = transactionSum + +tran.amount!;
        });
      });
    }
    return fullBudgetSum - transactionSum;
  }

  public getNumberOfTransactions(budgetId: string) {
    const budget = this.allBudgetsForUser.find(
      (budge) => budge.Id === budgetId
    );
    let transactionCount = 0;

    if (budget) {
      budget.categories.forEach((cat) => {
        transactionCount = transactionCount + +cat.transactions.length;
      });
    }

    return transactionCount;
  }

  @action goBackwardInTime() {
    if (this.indexOfCurrentBudget !== this.allBudgetsForUser.length - 1) {
      this.currentBudgetId = this.allBudgetsForUser[
        this.indexOfCurrentBudget + 1
      ].Id;
      sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
    }
  }

  @action
  public goForwardInTime() {
    if (this.indexOfCurrentBudget !== 0) {
      this.currentBudgetId = this.allBudgetsForUser[
        this.indexOfCurrentBudget - 1
      ].Id;
      sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
    }
  }

  @action
  public deleteBudget(budgetId: string) {
    const budget = this.allBudgetsForUser.find(
      (budget) => budget.Id === budgetId
    );
    this.dbClient.deleteBudget(budgetId);
    budget?.categories.forEach((category) => {
      category.transactions.forEach((tran) =>
        this.dbClient.deleteTransaction(tran.Id)
      );
      this.dbClient.deleteCategory(category.Id);
    });
    budget?.categoryGroups.forEach((catGroup) => {
      this.dbClient.deleteCategoryGroup(catGroup.Id);
    });

    this.allBudgetsForUser = this.allBudgetsForUser.filter(
      (budget) => budget.Id !== budgetId
    );
    this.currentBudgetId = this.getBudgetIdOfBudgetForTheCurrentMonth(
      this.allBudgetsForUser
    );
    sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
    sessionStorage.setItem(
      "allBudgetsForUser",
      JSON.stringify(this.allBudgetsForUser)
    );
  }

  @action
  public deleteTransaction(transactionId: string, categoryId: string) {
    this.dbClient.deleteTransaction(transactionId);
    this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
      if (budget.Id === this.currentBudget.Id) {
        budget.categories.replace(
          budget.categories.map((cat) => {
            if (cat.Id === categoryId) {
              var transactionToRemove = cat.transactions.find(
                (trans) => trans.Id === transactionId
              );
              if (transactionToRemove) {
                cat.transactions.remove(transactionToRemove);
              }
            }
            return cat;
          })
        );
      }
      return budget;
    });
    sessionStorage.setItem(
      "allBudgetsForUser",
      JSON.stringify(this.allBudgetsForUser)
    );
  }

  @action
  public deleteCategory(category: Category) {
    this.dbClient.deleteCategory(category.Id);
    category.transactions?.forEach((transaction) => {
      this.dbClient.deleteTransaction(transaction.Id);
    });

    if (this.allBudgetsForUser.length > 0) {
      this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
        if (budget.Id === category.budgetId) {
          budget.categories.replace(
            budget.categories.filter((cat) => cat.Id !== category.Id)
          );
        }
        return budget;
      });

      sessionStorage.setItem(
        "allBudgetsForUser",
        JSON.stringify(this.allBudgetsForUser)
      );
    }
  }

  @action
  public async getBudgetsByFamilyId(familyId: string) {
    if (
      (this.allBudgetsForUser.length === 0 || familyId !== "") &&
      !this.isBudgetListInSession()
    ) {
      let allBudgets = await this.dbClient.getAllBudgetsForFamily(familyId);
      allBudgets = this.sortBudgetList(allBudgets);
      let finalAllBudgets = [] as Budget[];
      for (const budget of allBudgets) {
        let categories = await this.dbClient.getAllCategoriesForBudget(
          budget.Id
        );
        let categoryGroups = await this.dbClient.getAllCategoryGroupsForBudget(
          budget.Id
        );
        let transactions = await this.dbClient.getAllTransactionsForCategoriesList(
          categories.map((category) => category.Id)
        );
        categories = categories.map((category) => {
          category.transactions = observable(
            this.sortTransactionByDate(
              transactions.filter((transaction) => {
                return transaction.categoryId === category.Id;
              })
            )
          );
          return category;
        });
        categoryGroups = categoryGroups.map((catGroup) => {
          catGroup.categories = observable(
            this.sortCategoriesByAmountLeft(
              categories.filter((cat) => cat.categoryGroupId === catGroup.Id)
            )
          );
          return catGroup;
        });
        const ungroupCatGroup = observable(
          this.sortCategoriesByAmountLeft(
            categories.filter((cat) => cat.categoryGroupId === "")
          )
        );
        categoryGroups.push({
          categories: ungroupCatGroup,
          name: "Ungrouped",
          precedent: categoryGroups.length + 1,
          Id: Guid.create().toString(),
          budgetId: budget.Id,
        } as CategoryGroup);
        budget.categoryGroups.replace(
          this.sortCategoryGroupsByPrecedent(categoryGroups)
        );
        budget.categories.replace(this.sortCategoriesByAmountLeft(categories));
        finalAllBudgets.push(budget);
      }

      this.allBudgetsForUser = finalAllBudgets;
      this.currentBudgetId = this.getBudgetIdOfBudgetForTheCurrentMonth(
        finalAllBudgets
      );
      sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
      sessionStorage.setItem(
        "allBudgetsForUser",
        JSON.stringify(this.allBudgetsForUser)
      );
    }
  }

  public getBudgetById(budgetId: string) {
    return this.dbClient.getBudgetById(budgetId).then(async (budget) => {
      if (budget) {
        await this.dbClient
          .getAllCategoriesForBudget(budgetId)
          .then(async (categories) => {
            await this.dbClient
              .getAllTransactionsForCategoriesList(
                categories.map((category) => category.Id)
              )
              .then((transactionArray) => {
                return budget.categories.replace(
                  this.sortCategoriesByAmountLeft(
                    categories.map((category) => {
                      category.transactions = observable(
                        transactionArray.filter((transaction) => {
                          return transaction.categoryId === category.Id;
                        })
                      );
                      return category;
                    })
                  )
                );
              });
            return budget;
          })
          .catch((error) => {
            return new Budget();
          });

        await this.dbClient
          .getAllCategoryGroupsForBudget(budgetId)
          .then((groups) => {
            budget.categoryGroups = observable<CategoryGroup>(groups);
          });

        return budget;
      } else {
        return new Budget();
      }
    });
  }

  public getAllPreviousBudgetDates(): Date[] {
    return this.allBudgetsForUser.map((budget) => budget.date);
  }

  @action
  public addNewCategoryToBudget(category: Category) {
    category.Id = Guid.create().toString();

    this.dbClient.putCategory(category);
    if (this.allBudgetsForUser.length > 0) {
      this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
        if (budget.Id === category.budgetId) {
          budget.categories.push(category);
        }
        return budget;
      });
      sessionStorage.setItem(
        "allBudgetsForUser",
        JSON.stringify(this.allBudgetsForUser)
      );
    }
  }

  @action
  public addNewTransactionToBudget(transaction: Transaction) {
    transaction.Id = Guid.create().toString();
    this.dbClient.putTransaction(transaction);
    this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
      if (budget.Id === this.currentBudget.Id) {
        budget.categories.replace(
          budget.categories.map((category) => {
            if (category.Id === transaction.categoryId) {
              category.transactions.push(transaction);
            }
            return category;
          })
        );
      }
      return budget;
    });
    sessionStorage.setItem(
      "allBudgetsForUser",
      JSON.stringify(this.allBudgetsForUser)
    );
  }

  @action
  public addBudgetFromCopy(newBudget: Budget, date: Date) {
    newBudget.familyId = toJS(this.currentBudget.familyId);
    var copyBudget = this.allBudgetsForUser.find(
      (budget) =>
        date.getMonth() === budget.date.getMonth() &&
        date.getFullYear() === budget.date.getFullYear()
    );

    var oldCategories = toJS(
      copyBudget!.categories.map((category) => {
        var newCategory = new Category();
        newCategory.amount = category.amount;
        newCategory.name = category.name;
        newCategory.categoryGroupId = category.categoryGroupId;
        return newCategory;
      })
    );

    var oldCategoryGroups = toJS(
      copyBudget!.categoryGroups
        .map((categoryGroup) => {
          var newCategoryGroup = new CategoryGroup();
          newCategoryGroup.name = categoryGroup.name;
          newCategoryGroup.Id = Guid.create().toString();
          newCategoryGroup.precedent = categoryGroup.precedent;
          oldCategories = oldCategories.map((category) => {
            if (category.categoryGroupId === categoryGroup.Id) {
              category.categoryGroupId = newCategoryGroup.Id;
            }
            return category;
          });
          newCategoryGroup.categories = observable<Category>(
            oldCategories.filter(
              (cat) => cat.categoryGroupId === newCategoryGroup.Id
            )
          );
          return newCategoryGroup;
        })
        .filter((group) => group.name.toLowerCase() !== "ungrouped")
    );

    let newCurrentBudgetId = this.addNewBudget(newBudget);

    oldCategoryGroups.forEach((categoryGroup) => {
      categoryGroup.budgetId = newCurrentBudgetId;
      this.addNewCategoryGroupToBudget(categoryGroup);
    });

    oldCategories.forEach((category) => {
      category.budgetId = newCurrentBudgetId;
      this.addNewCategoryToBudget(category);
    });

    this.currentBudgetId = newCurrentBudgetId;
    sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
  }

  @action
  public addBudgetFromBlank(budget: Budget) {
    budget.familyId = toJS(this.currentBudget.familyId);
    var newBudgetId = this.addNewBudget(budget);
    this.currentBudgetId = newBudgetId;
    sessionStorage.setItem("currentBudgetId", this.currentBudgetId);
  }

  @action
  public updateCategory(category: Category) {
    try {
      this.dbClient.updateCategory(
        category.Id,
        category.name,
        category.amount,
        category.categoryGroupId
      );
    } catch {
      //do nothing
    }
    if (this.allBudgetsForUser.length > 0) {
      this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
        if (budget.Id === category.budgetId) {
          budget.categories.replace(
            budget.categories.map((cat) => {
              if (cat.Id === category.Id) {
                return category;
              }
              return cat;
            })
          );
        }
        return budget;
      });

      sessionStorage.setItem(
        "allBudgetsForUser",
        JSON.stringify(this.allBudgetsForUser)
      );
    }
  }

  @action
  public updateTransaction(transaction: Transaction) {
    this.dbClient.updateTransaction(
      transaction.Id,
      transaction.description,
      transaction.amount!,
      transaction.date,
      transaction.categoryId
    );
    this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
      if (budget.Id === this.currentBudget.Id) {
        budget.categories.replace(
          budget.categories.map((cat) => {
            if (cat.Id === transaction.categoryId) {
              cat.transactions.replace(
                cat.transactions.map((tran) => {
                  if (tran.Id === transaction.Id) {
                    return transaction;
                  }
                  return tran;
                })
              );
            }
            return cat;
          })
        );
      }
      return budget;
    });
    sessionStorage.setItem(
      "allBudgetsForUser",
      JSON.stringify(this.allBudgetsForUser)
    );
  }

  @action
  private addNewCategoryGroupToBudget(categoryGroup: CategoryGroup) {
    this.dbClient.putCategoryGroup(categoryGroup);
    if (this.allBudgetsForUser.length > 0) {
      this.allBudgetsForUser = this.allBudgetsForUser.map((budget) => {
        if (budget.Id === categoryGroup.budgetId) {
          budget.categoryGroups.push(categoryGroup);
        }
        return budget;
      });

      sessionStorage.setItem(
        "allBudgetsForUser",
        JSON.stringify(this.allBudgetsForUser)
      );
    }
  }

  @action
  private addNewBudget(budget: Budget) {
    budget.Id = Guid.create().toString();
    this.dbClient.putBudget(budget);
    this.allBudgetsForUser.push(budget);
    this.allBudgetsForUser = this.sortBudgetList(this.allBudgetsForUser);
    sessionStorage.setItem(
      "allBudgetsForUser",
      JSON.stringify(this.allBudgetsForUser)
    );
    return budget.Id;
  }

  private getBudgetIdOfBudgetForTheCurrentMonth(budgetList: Budget[]) {
    var currentMonth = new Date().getMonth() + 1;
    var budgetId = budgetList[0].Id;
    budgetList.forEach((budget) => {
      var thisBudgetsMonth = budget.date.getMonth() + 1;
      if (thisBudgetsMonth === currentMonth) {
        budgetId = budget.Id;
      }
    });

    return budgetId;
  }

  private isBudgetListInSession(): boolean {
    var budgetsRawJson = sessionStorage.getItem("allBudgetsForUser");
    if (budgetsRawJson) {
      var budgets = JSON.parse(budgetsRawJson) as Budget[];
      if (budgets.length > 0) {
        this.parseBudgetFromSession(budgets);
        return true;
      }
    }
    return false;
  }

  private parseBudgetFromSession(budgets: Budget[]) {
    this.allBudgetsForUser = budgets.map((budget) => {
      var newBudget = new Budget();
      newBudget.Id = budget.Id;
      newBudget.date = new Date(budget.date);
      newBudget.familyId = budget.familyId;
      newBudget.categories = observable<Category>(
        this.sortCategoriesByAmountLeft(
          budget.categories.map((category) => {
            var newCategory = new Category();
            newCategory.Id = category.Id;
            newCategory.amount = category.amount as number;
            newCategory.budgetId = category.budgetId;
            newCategory.name = category.name;
            newCategory.categoryGroupId = category.categoryGroupId;
            newCategory.transactions = observable<Transaction>(
              this.sortTransactionByDate(
                category.transactions.map((trans) => {
                  var newTransaction = new Transaction();
                  newTransaction.Id = trans.Id;
                  newTransaction.amount = trans.amount as number;
                  newTransaction.categoryId = trans.categoryId;
                  newTransaction.date = new Date(trans.date);
                  newTransaction.description = trans.description;
                  return newTransaction;
                })
              )
            );
            return newCategory;
          })
        )
      );
      newBudget.categoryGroups = observable<CategoryGroup>(
        this.sortCategoryGroupsByPrecedent(
          budget.categoryGroups.map((categoryGroup) => {
            var newCategoryGroup = new CategoryGroup();
            newCategoryGroup.Id = categoryGroup.Id;
            newCategoryGroup.name = categoryGroup.name;
            newCategoryGroup.budgetId = categoryGroup.budgetId;
            newCategoryGroup.precedent = categoryGroup.precedent;

            if (newCategoryGroup.name === "Ungrouped") {
              newCategoryGroup.categories = observable<Category>(
                newBudget.categories.filter((cat) => cat.categoryGroupId === "")
              );
            } else {
              newCategoryGroup.categories = observable<Category>(
                newBudget.categories.filter(
                  (cat) => cat.categoryGroupId === newCategoryGroup.Id
                )
              );
            }

            return newCategoryGroup;
          })
        )
      );
      return newBudget;
    });
    var currentBudgetId = sessionStorage.getItem("currentBudgetId");
    if (currentBudgetId) {
      this.currentBudgetId = currentBudgetId;
    } else {
      this.currentBudgetId = this.getBudgetIdOfBudgetForTheCurrentMonth(
        this.allBudgetsForUser
      );
    }
  }

  private sortBudgetList(budgetList: Budget[]) {
    var sortedBudgets = budgetList.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }

      return 0;
    });

    return sortedBudgets;
  }

  private sortCategoriesByAmountLeft(categoryList: Category[]): Category[] {
    return categoryList.sort((a, b) => {
      var amountLeftA = +(toJS(a.amount) as number);
      a.transactions.forEach((transaction) => {
        amountLeftA = amountLeftA - transaction.amount;
      });
      var amountLeftB = +(toJS(b.amount) as number);
      b.transactions.forEach((transaction) => {
        amountLeftB = amountLeftB - transaction.amount;
      });

      if (amountLeftA > amountLeftB) {
        return -1;
      } else if (amountLeftA < amountLeftB) {
        return 1;
      }
      return 0;
    });
  }

  private sortTransactionByDate(transactionList: Transaction[]): Transaction[] {
    return transactionList.sort((a, b) => {
      var dateA = a.date as Date;
      var dateB = b.date as Date;

      if (dateA > dateB) {
        return -1;
      } else if (dateA < dateB) {
        return 1;
      }

      return 0;
    });
  }

  private sortCategoryGroupsByPrecedent(
    categoryGroupList: CategoryGroup[]
  ): CategoryGroup[] {
    return categoryGroupList.sort((a, b) => {
      if (a.precedent > b.precedent) {
        return 1;
      } else if (a.precedent < b.precedent) {
        return -1;
      }

      return 0;
    });
  }
}
