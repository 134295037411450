import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../EnhancedTable/EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import IEnhancedTableHeadCell from "../../Domain/IEnhancedTableHeadCell";
import IEnhancedTableRow, { Data } from "../../Domain/IEnhancedTableRow";
import NumberFormat from "react-number-format";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export interface IEnhancedTableProps {
  title: string;
  rows: IEnhancedTableRow[];
  headCells: IEnhancedTableHeadCell[];
}

const EnhancedTable: React.FC<IEnhancedTableProps> = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar title={props.title} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              rowCount={props.rows.length}
              headCells={props.headCells}
            />
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      {row.values.map((dataItem: Data, index: number) => {
                        if (dataItem.display) {
                          return (
                            <React.Fragment key={row.name + index}>
                              <TableCell
                                align={index === 0 ? "left" : "right"}
                                size="small"
                                style={{ width: "20%" }}
                              >
                                {dataItem.currency && (
                                  <NumberFormat
                                    value={dataItem.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={"$"}
                                  />
                                )}
                                {!dataItem.currency && (
                                  <React.Fragment>
                                    {dataItem.value}
                                  </React.Fragment>
                                )}
                              </TableCell>
                            </React.Fragment>
                          );
                        }
                        return <React.Fragment></React.Fragment>;
                      })}
                      {row.editButton && row.editButtonClicked !== undefined && (
                        <TableCell size="small" style={{ width: "20%" }}>
                          <EditIcon
                            fontSize="small"
                            onClick={() => row.editButtonClicked(row.name)}
                          />
                        </TableCell>
                      )}
                      {row.deleteButton &&
                        row.deleteButtonClicked !== undefined && (
                          <TableCell size="small" style={{ width: "20%" }}>
                            <DeleteIcon
                              fontSize="small"
                              onClick={() => row.deleteButtonClicked(row.name)}
                            />
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
