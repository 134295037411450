import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Category from "../Domain/Category";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import NumberFormat from "react-number-format";
import EditIcon from "@material-ui/icons/Edit";
import { RouteComponentProps } from "react-router-dom";
import Budget from "../Domain/Budget";

const useStyles = makeStyles({
  root: { width: "100%" },
});

export interface IBudgetEditor
  extends RouteComponentProps<{
    budgetId: string;
  }> {
  store?: MasterStore;
}

const BudgetEditor: React.FC<IBudgetEditor> = (props) => {
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [sumOfCategories, setSumOfCategories] = useState(0);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [budgetToEdit, setBudgetToEdit] = useState(new Budget());
  const budgetId = props.match.params.budgetId;
  const [currentEditingCategory, setCurrentEditingCategory] = useState(
    new Category()
  );
  const classes = useStyles();
  useEffect(() => {
    if (budgetId) {
      props.store!.budgetStore.getBudgetById(budgetId).then((budget) => {
        if (budget) {
          setBudgetToEdit(budget);
          setSumOfCategories(calculateSum(budget));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCategory = (category: Category) => {
    category.budgetId = budgetId;
    props.store!.budgetStore.addNewCategoryToBudget(category);
    let newBudget = budgetToEdit;
    newBudget.categories.push(category);
    setBudgetToEdit(newBudget);
    setSumOfCategories(calculateSum(budgetToEdit));
    setShowNewCategoryModal(false);
  };

  const editCategory = (category: Category) => {
    props.store!.budgetStore.updateCategory(category);
    let newBudget = budgetToEdit;
    newBudget.categories.replace(
      budgetToEdit.categories.map((cat) => {
        if (cat.Id === category.Id) {
          return category;
        } else {
          return cat;
        }
      })
    );
    setBudgetToEdit(newBudget);
    setSumOfCategories(calculateSum(budgetToEdit));
    setShowEditCategoryModal(false);
  };

  const removeCategoryFunction = (category: Category) => {
    props.store!.budgetStore.deleteCategory(category);
    let newBudget = budgetToEdit;
    newBudget.categories.replace(
      budgetToEdit.categories.filter((cat) => cat.Id !== category.Id)
    );
    setSumOfCategories(calculateSum(budgetToEdit));
    setBudgetToEdit(newBudget);
  };

  const calculateSum = (budget: Budget): number => {
    var sums = 0;
    budget.categories.forEach((category) => {
      sums = sums + +category.amount;
    });
    return sums;
  };

  const getCategoryGroup = (categoryGroupId: string): string => {
    var returnString = "";
    budgetToEdit.categoryGroups.forEach((catGroup) => {
      if (catGroup.Id === categoryGroupId) {
        returnString = catGroup.name;
      }
    });
    return returnString;
  };

  return (
    <React.Fragment>
      <Modal
        open={showNewCategoryModal}
        onClose={() => {
          setShowNewCategoryModal(false);
        }}
        aria-labelledby="New Category Modal"
        aria-describedby="This is the new category modal, enter name and amount then click save"
      >
        <Paper>
          <AddCategory addCategory={addCategory} />
        </Paper>
      </Modal>
      <Modal
        open={showEditCategoryModal}
        onClose={() => {
          setShowEditCategoryModal(false);
        }}
        aria-labelledby="Edit Category Modal"
        aria-describedby="This is the edit category modal, modify the name and amount then click save"
      >
        <Paper>
          <EditCategory
            editCategory={editCategory}
            initialCategory={currentEditingCategory}
            listOfCategoryGroups={budgetToEdit.categoryGroups}
          />
        </Paper>
      </Modal>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <h2>Budget Editor</h2>
        </Grid>
        <Grid item>
          {budgetToEdit && (
            <Typography>{budgetToEdit?.date.toLocaleDateString()}</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          Total:{" "}
          <NumberFormat
            value={sumOfCategories}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"$"}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Category Group</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <AddCircleIcon
                        onClick={() => setShowNewCategoryModal(true)}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {budgetToEdit?.categories.map((category, i) => {
                    return (
                      <TableRow key={"CategoryRow" + i}>
                        <TableCell>{category.name}</TableCell>
                        <TableCell>
                          {getCategoryGroup(category.categoryGroupId)}
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={category.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={"$"}
                          />
                        </TableCell>
                        <TableCell>
                          <EditIcon
                            onClick={() => {
                              setShowEditCategoryModal(true);
                              setCurrentEditingCategory(category);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <RemoveCircleIcon
                            onClick={() => removeCategoryFunction(category)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default inject("store")(observer(BudgetEditor));
