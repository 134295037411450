import AWS from "aws-sdk";
import FamilyMember from "../Domain/FamilyMember";
import { InvitationStatus } from "../Domain/InvitationStatus";
import Budget from "../Domain/Budget";
import Category from "../Domain/Category";
import Transaction from "../Domain/Transaction";
import CategoryGroup from "../Domain/CategoryGroup";
import Account from "../Domain/Account";
import { ClientBase } from "./ClientBase";
import PlaidItem from "../Domain/PlaidItem";

export default interface IDynamoDBClient {
  getFamilyMembers: (familyId: string) => Promise<FamilyMember[]>;
}

export class DynamoDBClient extends ClientBase implements IDynamoDBClient {
  public async putFamilyMember(familyMember: FamilyMember) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    let params = {
      Item: familyMember,
      TableName: "budgeit_familymember",
    };
    await docClient
      .put(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public async putCategory(category: Category) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    var putCat = {
      Id: category.Id,
      budgetId: category.budgetId,
      name: category.name,
      amount: category.amount,
      categoryGroupId: category.categoryGroupId,
    };

    let params = {
      Item: putCat,
      TableName: "budgeit_categories",
    };
    await docClient
      .put(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public async putCategoryGroup(categoryGroup: CategoryGroup) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    var putCatGroup = {
      Id: categoryGroup.Id,
      budgetId: categoryGroup.budgetId,
      name: categoryGroup.name,
      precedent: categoryGroup.precedent,
    };

    let params = {
      Item: putCatGroup,
      TableName: "budgeit_categorygroup",
    };
    await docClient
      .put(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public async putTransaction(transaction: Transaction) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    var putItem = {
      Id: transaction.Id,
      description: transaction.description,
      amount: transaction.amount,
      categoryId: transaction.categoryId,
      date: transaction.date.toLocaleString(),
      familyId: transaction.familyId,
    };

    let params = {
      Item: putItem,
      TableName: "budgeit_transactions",
    };
    await docClient
      .put(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public async putBudget(budget: Budget) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    var putItem = {
      Id: budget.Id,
      date: budget.date.toLocaleString(),
      familyId: budget.familyId,
    };

    let params = {
      Item: putItem,
      TableName: "budgeit_budget",
    };
    await docClient
      .put(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public async getFamilyMembers(familyId: string): Promise<FamilyMember[]> {
    var returndata = [] as FamilyMember[];
    if (familyId) {
      var params = {
        TableName: "budgeit_familymember",
        FilterExpression: "familyId = :this_familyId",
        ExpressionAttributeValues: { ":this_familyId": familyId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        data.Items!.forEach((item: any) => {
          returndata.push(item as FamilyMember);
        });
      }
    }
    return returndata;
  }

  public async getMemberByEmail(email: string) {
    if (email) {
      var params = {
        TableName: "budgeit_familymember",
        FilterExpression: "emailAddress = :emailAddress",
        ExpressionAttributeValues: { ":emailAddress": email },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        return data.Items![0] as FamilyMember;
      }
    }
  }

  public async getBudgetById(budgetId: string) {
    if (budgetId) {
      var params = {
        TableName: "budgeit_budget",
        FilterExpression: "Id = :Id",
        ExpressionAttributeValues: { ":Id": budgetId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        return this.castBudgetFields(data.Items![0]);
      }
    }
  }

  public async getAllBudgetsForFamily(familyId: string): Promise<Budget[]> {
    var returndata = [] as Budget[];
    if (familyId) {
      var params = {
        TableName: "budgeit_budget",
        FilterExpression: "familyId = :familyId",
        ExpressionAttributeValues: { ":familyId": familyId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        data.Items!.forEach((item: any) => {
          returndata.push(this.castBudgetFields(item));
        });
      }
    }
    return returndata;
  }

  public async getAllAccountsForFamily(familyId: string): Promise<Account[]> {
    var returndata = [] as Account[];
    if (familyId) {
      var params = {
        TableName: "budgeit_accounts",
        FilterExpression: "familyId = :familyId",
        ExpressionAttributeValues: { ":familyId": familyId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        data.Items!.forEach((item: any) => {
          returndata.push(this.castAccountFields(item));
        });
      }
    }
    return returndata;
  }

  public async getAllCategoriesForBudget(
    budgetId: string
  ): Promise<Category[]> {
    var returndata = [] as Category[];
    if (budgetId) {
      var params = {
        TableName: "budgeit_categories",
        FilterExpression: "budgetId = :budgetId",
        ExpressionAttributeValues: { ":budgetId": budgetId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        data.Items!.forEach((item: any) => {
          returndata.push(this.castCategoryFields(item));
        });
      }
    }
    return returndata;
  }

  public async getAllCategoryGroupsForBudget(
    budgetId: string
  ): Promise<CategoryGroup[]> {
    var returndata = [] as CategoryGroup[];
    if (budgetId) {
      var params = {
        TableName: "budgeit_categorygroup",
        FilterExpression: "budgetId = :budgetId",
        ExpressionAttributeValues: { ":budgetId": budgetId },
      };
      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));

      if (data) {
        data.Items!.forEach((item: any) => {
          returndata.push(item as CategoryGroup);
        });
      }
    }
    return returndata;
  }

  public async getAllTransactionsForCategoriesList(
    categories: string[]
  ): Promise<Transaction[]> {
    let transactionFullList = [] as Transaction[];
    if (categories && categories.length > 0) {
      var params = {
        TableName: "budgeit_transactions",
        FilterExpression: this.createFilterExpression(categories.length),
        ExpressionAttributeValues: this.createExpressionAttributeValues(
          categories
        ),
      };

      var docClient = new AWS.DynamoDB.DocumentClient();
      const data = await docClient
        .scan(params)
        .promise()
        .catch((error) => console.log(error));
      if (data) {
        data.Items!.forEach((item: any) => {
          transactionFullList.push(this.castTransactionFields(item));
        });
      }
    }
    return transactionFullList;
  }

  public updateFamilyMemberId(familyId: string, memberId: string) {
    const params = {
      TableName: "budgeit_familymember",
      Key: { Id: memberId },
      UpdateExpression: "set #familyId = :familyId",
      ExpressionAttributeNames: { "#familyId": "familyId" },
      ExpressionAttributeValues: {
        ":familyId": familyId,
      },
    };
    const documentClient = new AWS.DynamoDB.DocumentClient();

    documentClient
      .update(params, function (err, data) {
        if (err) console.log(err);
        else console.log(data);
      })
      .promise();
  }

  public updateFamilyMemberInvitationStatus(
    status: InvitationStatus,
    memberId: string
  ) {
    const params = {
      TableName: "budgeit_familymember",
      Key: { Id: memberId },
      UpdateExpression: "set #status = :status",
      ExpressionAttributeNames: { "#status": "status" },
      ExpressionAttributeValues: {
        ":status": status,
      },
    };
    const documentClient = new AWS.DynamoDB.DocumentClient();

    documentClient
      .update(params, function (err, data) {
        if (err) console.log(err);
        else console.log(data);
      })
      .promise();
  }

  public updateCategory(
    id: string,
    name: string,
    amount: number,
    categoryGroupId: string
  ) {
    const params = {
      TableName: "budgeit_categories",
      Key: { Id: id },
      UpdateExpression:
        "set #name = :name, #amount = :amount, #categoryGroupId = :categoryGroupId",
      ExpressionAttributeNames: {
        "#name": "name",
        "#amount": "amount",
        "#categoryGroupId": "categoryGroupId",
      },
      ExpressionAttributeValues: {
        ":amount": amount,
        ":name": name,
        ":categoryGroupId": categoryGroupId,
      },
    };
    const documentClient = new AWS.DynamoDB.DocumentClient({
      correctClockSkew: true,
    });

    documentClient.update(params, function (err, data) {}).promise();
  }

  public updateTransaction(
    id: string,
    description: string,
    amount: number,
    date: Date,
    categoryId: string
  ) {
    const params = {
      TableName: "budgeit_transactions",
      Key: { Id: id },
      UpdateExpression:
        "set #description = :description, #amount = :amount, #date = :date, #categoryId = :categoryId",
      ExpressionAttributeNames: {
        "#name": "name",
        "#amount": "amount",
        "#date": "date",
        "#categoryId": "categoryId",
      },
      ExpressionAttributeValues: {
        ":description": description,
        ":amount": amount,
        ":date": date.toLocaleString(),
        ":categoryId": categoryId,
      },
    };
    const documentClient = new AWS.DynamoDB.DocumentClient();

    documentClient
      .update(params, function (err, data) {
        if (err) console.log(err);
        else console.log(data);
      })
      .promise();
  }

  public deleteTransaction(Id: string) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    let params = {
      TableName: "budgeit_transactions",
      Key: {
        Id: Id,
      },
    };

    docClient
      .delete(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public deleteCategory(Id: string) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    let params = {
      TableName: "budgeit_categories",
      Key: {
        Id: Id,
      },
    };

    docClient
      .delete(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public deleteCategoryGroup(Id: string) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    let params = {
      TableName: "budgeit_categorygroup",
      Key: {
        Id: Id,
      },
    };

    docClient
      .delete(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  public deleteBudget(Id: string) {
    var docClient = new AWS.DynamoDB.DocumentClient({
      convertEmptyValues: true,
    });

    let params = {
      TableName: "budgeit_budget",
      Key: {
        Id: Id,
      },
    };

    docClient
      .delete(params)
      .promise()
      .catch((error) => {
        console.log(error);
      });
  }

  async addAccounts(accounts: Account[]): Promise<void> {
    await this.Setup();
    await fetch(
      this.clientConnectionBase + "account",
      this.getOptionsForClient("PUT", accounts)
    ).catch((error) => {
      console.log(error);
    });
  }

  async updateUnprocessedTransaction(
    transactionId: string,
    categoryId: string,
    ignore: boolean
  ) {
    await this.Setup();

    var body = {
      transactionId: transactionId,
      categoryId: categoryId,
      ignore: ignore,
    };

    await fetch(
      this.clientConnectionBase + "transaction/unprocessedtransactions",
      this.getOptionsForClient("POST", body)
    );
  }

  async getUnprocessedTransactions(familyId: string): Promise<Transaction[]> {
    let transactionList = [] as Transaction[];

    await this.Setup();
    let response = await fetch(
      this.clientConnectionBase +
        "transaction/unprocessedtransactions?familyId=" +
        familyId,
      this.getOptionsForClient("GET", null)
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
    response.forEach((t: any) =>
      transactionList.push(this.castTransactionFields(t))
    );
    return transactionList;
  }

  async getPlaidItems(familyId: string): Promise<PlaidItem[]> {
    let itemsList = [] as PlaidItem[];

    await this.Setup();
    await fetch(
      this.clientConnectionBase + "plaiditem?familyId=" + familyId,
      this.getOptionsForClient("GET", null)
    )
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          response.items.forEach((plaidItem: any) => {
            itemsList.push(plaidItem as PlaidItem);
          });
        }
      })
      .catch((err) => console.log(err));

    return itemsList;
  }

  async setIsConnectedForAccount(
    accountId: string,
    isConnected: boolean
  ): Promise<void> {
    var body = {
      account_id: accountId,
      isConnected: isConnected,
    };
    await this.Setup();
    await fetch(
      this.clientConnectionBase + "account",
      this.getOptionsForClient("POST", body)
    ).catch((err) => console.log(err));
  }

  async deleteAccountsForFamily(familyId: string) {
    await this.Setup();
    await fetch(
      this.clientConnectionBase + "account?familyId=" + familyId,
      this.getOptionsForClient("DELETE", null)
    ).catch((err) => console.log(err));
  }

  private castBudgetFields(item: any): Budget {
    let budgetReturn = new Budget();
    budgetReturn.Id = item.Id;
    budgetReturn.date = new Date(item.date);
    budgetReturn.familyId = item.familyId;
    return budgetReturn;
  }

  private castAccountFields(item: any): Account {
    let accountReturn = new Account();
    accountReturn.account_id = item.Id;
    accountReturn.name = item.name;
    accountReturn.familyId = item.familyId;
    accountReturn.availableBalance = item.availableBalance;
    accountReturn.isConnected = !!item.isConnected;
    accountReturn.lastBalancedDate = new Date(item.lastBalaceDate);
    return accountReturn;
  }

  private castTransactionFields(item: any): Transaction {
    let transactionReturn = new Transaction();
    transactionReturn.Id = item.Id;
    transactionReturn.categoryId = item.categoryId;
    transactionReturn.description = item.description;
    transactionReturn.amount = item.amount;
    transactionReturn.date = new Date(item.date);
    transactionReturn.familyId = item.familyId;
    return transactionReturn;
  }

  private castCategoryFields(item: any): Category {
    let categoryReturn = new Category();
    categoryReturn.Id = item.Id;
    categoryReturn.amount = item.amount;
    categoryReturn.budgetId = item.budgetId;
    categoryReturn.categoryGroupId = item.categoryGroupId ?? "";
    categoryReturn.name = item.name;
    return categoryReturn;
  }

  private createFilterExpression(length: number) {
    var expression = "categoryId IN (";

    if (length > 0) {
      for (var i = 0; i < length; i++) {
        if (i === 0) {
          expression = expression.concat(":catId");
        } else {
          expression = expression.concat(", :catId");
        }
        expression = expression.concat(i.toString());
      }
    }

    return expression.concat(")");
  }

  private createExpressionAttributeValues(categories: String[]) {
    var attributeValueExpression = {} as any;

    if (categories.length > 0) {
      categories.forEach((cat, i) => {
        attributeValueExpression[":catId" + i.toString()] = cat;
      });
    }

    return attributeValueExpression;
  }
}
