import { InvitationStatus } from "./InvitationStatus";
import { FamilyMemberRole } from "./FamilyMemberRole";

export default class FamilyMember {
  Id: string = "";
  familyId: string = "";
  emailAddress: string = "";
  status: InvitationStatus = InvitationStatus.None;
  role: FamilyMemberRole = FamilyMemberRole.Standard;
}
