import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface IEnhancedTableToolbarProps {
  title: string;
}

const EnhancedTableToolbar: React.FC<IEnhancedTableToolbarProps> = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
