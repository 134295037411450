import React from "react";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import IEnhancedTableHeadCell from "../../Domain/IEnhancedTableHeadCell";

interface IEnhancedTableHeadProps {
  classes: any;
  headCells: IEnhancedTableHeadCell[];
  rowCount: number;
}

export default function EnhancedTableHead(props: IEnhancedTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => {
          if (headCell.display) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
              >
                {headCell.label}
              </TableCell>
            );
          }
          return <React.Fragment></React.Fragment>;
        })}
      </TableRow>
    </TableHead>
  );
}
