import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { useParams, Redirect } from "react-router-dom";
import MasterStore from "../Stores/MasterStore";
import { AmplifySignUp, AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

export interface IUserInvitationProps {
  store?: MasterStore;
}

const UserInvitationProps: React.FC<IUserInvitationProps> = (props) => {
  const { email, familyId } = useParams();
  const [redirect, setRedirect] = useState(false);
  
  useEffect(() => {
    return onAuthUIStateChange((newAuthState: any) => {
      if (newAuthState === "signedin") {
        props.store?.familyManagementStore.updateMemberFamilyId(
          familyId,
          email
        );
        setRedirect(true);
      }
    });
  }, [email, familyId, props.store]);

  if (redirect) {
    return <Redirect to="/" />;
  } else {
    return (
      <React.Fragment>
        <h2>Welcome to Budge It!</h2>
        <h5>You have been invited to join a family</h5>
        <AmplifyAuthenticator
          usernameAlias="email"
          initialAuthState={AuthState.SignUp}
        >
          <AmplifySignUp
            usernameAlias="email"
            slot="sign-up"
            formFields={[{ type: "email" }, { type: "password" }]}
          ></AmplifySignUp>
        </AmplifyAuthenticator>
      </React.Fragment>
    );
  }
};

export default inject("store")(observer(UserInvitationProps));
