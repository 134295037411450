import React, { useEffect, useState } from "react";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Grid,
} from "@material-ui/core";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import CategoryHeader from "./CategoryHeader";
import TransactionRows from "./TransactionRows";
import Transaction from "../Domain/Transaction";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import TransactionGroup from "./TransactionGroup";
import CategoryGroupHeader from "./CategoryGroupHeader";
import CategoryGroup from "../Domain/CategoryGroup";
import Category from "../Domain/Category";

const useStyles = makeStyles({
  editBudgetButton: {
    width: "100%",
  },
});

export interface IBudgetOverview {
  store?: MasterStore;
}

const BudgetOverview: React.FC<IBudgetOverview> = (props) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const budgetStore = props.store?.budgetStore!;

  useEffect(() => {
    props.store!.authenticationStore.getAuthenticatedUser().then((user) => {
      if (user) {
        budgetStore.getBudgetsByFamilyId(user.familyId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTransactionFunction = (transaction: Transaction) => {
    budgetStore.addNewTransactionToBudget(transaction);
  };

  const removeTransactionFunction = (
    transactionId: string,
    categoryId: string
  ) => {
    budgetStore.deleteTransaction(transactionId, categoryId);
  };

  const editTransactionFunction = (transaction: Transaction) => {
    budgetStore.updateTransaction(transaction);
  };

  const nextBudget = () => {
    budgetStore.goForwardInTime();
  };

  const previousBudget = () => {
    budgetStore.goBackwardInTime();
  };

  const openExpandSection = (index: string) => {
    if (expanded === "expandedSection" + index) {
      setExpanded(false);
    } else {
      setExpanded("expandedSection" + index);
    }
  };

  return (
    <React.Fragment>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          {budgetStore?.currentBudget && (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                {budgetStore.hasPreviousBudget && (
                  <ArrowLeftIcon fontSize="large" onClick={previousBudget} />
                )}
              </Grid>
              <Grid item>
                <h2>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                  }).format(budgetStore?.currentBudget?.date)}
                </h2>
              </Grid>
              <Grid item>
                {budgetStore.hasNextBudget && (
                  <ArrowRightIcon fontSize="large" onClick={nextBudget} />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.editBudgetButton}>
          <Grid container direction="row" justify="flex-end">
            <Grid item>
              Budget Amount Left:{" "}
              <NumberFormat
                value={
                  budgetStore.currentBudgetCategorySums -
                  budgetStore.currentBudgetTransactionSums
                }
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </Grid>
            {budgetStore.currentBudgetHasTransactions && (
              <Grid item>
                <EditIcon
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editBudgetButton}>
          <Grid container>
            {budgetStore?.currentBudget && (
              <React.Fragment>
                <Grid container spacing={2}>
                  {budgetStore?.currentBudget?.categoryGroups.map(
                    (group: CategoryGroup, groupIndex: number) => {
                      return (
                        <Grid item xs={12} key={"gridItem" + groupIndex}>
                          <CategoryGroupHeader
                            groupName={group.name}
                            key={"header" + groupIndex}
                            displayHeader={group.categories.length > 0}
                          >
                            <Grid item xs={12}>
                              {group.categories.map(
                                (category: Category, categoryIndex: number) => {
                                  return (
                                    <Grid item xs={12} key={category.Id}>
                                      <Paper>
                                        <Typography component={"span"}>
                                          <CategoryHeader
                                            currentCategory={category}
                                            addTransactionClick={
                                              addTransactionFunction
                                            }
                                            categories={budgetStore.currentBudget.categories.slice()}
                                            expandTransactions={() =>
                                              openExpandSection(
                                                groupIndex.toString() +
                                                  categoryIndex.toString()
                                              )
                                            }
                                            index={categoryIndex}
                                          />
                                          <TransactionGroup
                                            expanded={expanded}
                                            index={
                                              groupIndex.toString() +
                                              categoryIndex.toString()
                                            }
                                          >
                                            <TableContainer>
                                              <Table
                                                aria-label="simple table"
                                                size="small"
                                              >
                                                {category.transactions && (
                                                  <TableBody>
                                                    <TransactionRows
                                                      transactions={
                                                        category.transactions
                                                      }
                                                      editTransactionFunction={
                                                        editTransactionFunction
                                                      }
                                                      removeTransactionFunction={
                                                        removeTransactionFunction
                                                      }
                                                      categoryList={
                                                        props.store!.budgetStore
                                                          .currentBudget
                                                          .categories
                                                      }
                                                      editMode={editMode}
                                                    />
                                                  </TableBody>
                                                )}
                                              </Table>
                                            </TableContainer>
                                          </TransactionGroup>
                                        </Typography>
                                      </Paper>
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          </CategoryGroupHeader>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default inject("store")(observer(BudgetOverview));
