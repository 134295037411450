import emailjs from "emailjs-com";
export class EmailClient {
  sendNewFamilyMemberInvitation(newMemberEmail: string, familyId: string) {
    var email = {
      to: newMemberEmail,
      link:
        "http://budge-it.com.s3-website-us-east-1.amazonaws.com/UserInvitation/" +
        familyId +
        "/" +
        newMemberEmail,
    };

    emailjs
      .send(
        "budgeitgmail",
        "addfamilymembertemplate",
        email,
        "user_3IabaTk3eBEeonKL6yOxh"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
}
