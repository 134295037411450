import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EmojiNatureIcon from "@material-ui/icons/EmojiNature";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import FaceIcon from "@material-ui/icons/Face";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Link } from "react-router-dom";
import AuthenticationStore from "../../Stores/AuthenticationStore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [authState, setAuthState] = React.useState("");
  const authStore = new AuthenticationStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  authStore
    .getAuthenticatedUser()
    .then((user) => {
      if (user) {
        setAuthState("signedin");
      }
    })
    .catch((error) => {});

  React.useEffect(() => {
    return onAuthUIStateChange((newAuthState: any) => {
      setAuthState(newAuthState);
    });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SignOutClick = () => {
    authStore.signOutUser().then(() => {
      setAuthState("signin");
      handleClose();
    });
  };

  return (
    <span>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <AccountCircleIcon fontSize="small" />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {authState === "signedin" && (
          <div>
            <Link
              to="/FamilyManagement"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <StyledMenuItem onClick={handleClose}>
                <ListItemIcon>
                  <FaceIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Family Management" />
              </StyledMenuItem>
            </Link>
            <Link
              to="Accounts"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <StyledMenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AccountBalanceIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Accounts" />
              </StyledMenuItem>
            </Link>
            <Link
              to="Transactions"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <StyledMenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AttachMoneyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
              </StyledMenuItem>
            </Link>
            <Link
              to="/Budgets"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <StyledMenuItem onClick={handleClose}>
                <ListItemIcon>
                  <ListAltIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Manage Budgets" />
              </StyledMenuItem>
            </Link>
            <StyledMenuItem onClick={SignOutClick}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </StyledMenuItem>
          </div>
        )}
        {authState !== "signedin" && (
          <Link
            to="/SignIn"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledMenuItem onClick={handleClose}>
              <ListItemIcon>
                <EmojiNatureIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </StyledMenuItem>
          </Link>
        )}
      </StyledMenu>
    </span>
  );
}
