import React, { useState } from "react";
import { TableRow, TableCell, Modal, Paper } from "@material-ui/core";
import Transaction from "../Domain/Transaction";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import NumberFormat from "react-number-format";
import EditIcon from "@material-ui/icons/Edit";
import EditTransaction from "./EditTransaction";
import Category from "../Domain/Category";

export interface ITransactionRow {
  transactions: Transaction[];
  removeTransactionFunction: (
    transactionId: string,
    categoryId: string
  ) => void;
  editTransactionFunction: (transaction: Transaction) => void;
  categoryList: Category[];
  editMode: boolean;
}

const TransactionRows: React.FC<ITransactionRow> = (props) => {
  const getDateString = (date: Date) => {
    return date.toLocaleDateString();
  };

  const [showEditTransactionModal, setShowEditTransactionModal] = useState(
    false
  );
  const [currentEditingTransaction, setCurrentEditingTransaction] = useState(
    new Transaction()
  );

  const localEditTransactionFunction = (transaction: Transaction) => {
    props.editTransactionFunction(transaction);
    setShowEditTransactionModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        open={showEditTransactionModal}
        onClose={() => {
          setShowEditTransactionModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper>
          <EditTransaction
            editTransactionFunction={localEditTransactionFunction}
            initialTransaction={currentEditingTransaction}
            categories={props.categoryList}
          />
        </Paper>
      </Modal>
      {props.transactions.map((transaction) => {
        return (
          <TableRow key={transaction.Id}>
            <TableCell component="th" scope="row">
              {transaction.description}
            </TableCell>
            <TableCell align="left">
              <NumberFormat
                value={transaction.amount}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </TableCell>
            <TableCell align="right">
              {getDateString(transaction.date)}
            </TableCell>
            {props.editMode && (
              <React.Fragment>
                <TableCell>
                  <EditIcon
                    onClick={() => {
                      setCurrentEditingTransaction(transaction);
                      setShowEditTransactionModal(true);
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <RemoveCircleIcon
                    onClick={() =>
                      props.removeTransactionFunction(
                        transaction.Id,
                        transaction.categoryId
                      )
                    }
                  />
                </TableCell>
              </React.Fragment>
            )}
          </TableRow>
        );
      })}
    </React.Fragment>
  );
};

export default TransactionRows;
