import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Grid,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { Component } from "react";
import Category from "../Domain/Category";
import CategoryGroup from "../Domain/CategoryGroup";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    width: 400,
  },
  justForTesting: {
    border: "1px solid green",
  },
});

export interface IEditCategory {
  initialCategory: Category;
  editCategory: (category: Category) => void;
  listOfCategoryGroups: CategoryGroup[];
  classes: any;
}

class EditCategory extends Component<IEditCategory> {
  state = {
    editedCategory: {
      ...this.props.initialCategory,
    },
  };

  inputChange = (event: any) => {
    this.setState({
      editedCategory: {
        ...this.state.editedCategory,
        [event.target.name]: event.target.value,
      } as Category,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Paper
          tabIndex={-1}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={this.props.classes.paper}
        >
          <h2>Edit Category</h2>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <TextField
                name="name"
                label="Name"
                id="description"
                margin="dense"
                variant="outlined"
                value={this.state.editedCategory.name}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item>
              <InputLabel id="categoryGroupId">Category</InputLabel>
              <Select
                labelId="categoryGroupId"
                id="categoryGroupSelectId"
                name="categoryGroupId"
                value={this.state.editedCategory.categoryGroupId}
                onChange={this.inputChange}
                className={this.props.classes.selectBox}
              >
                {this.props.listOfCategoryGroups.map((categoryGroup, i) => {
                  return (
                    <MenuItem key={"option" + i} value={categoryGroup.Id}>
                      {categoryGroup.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              <TextField
                name="amount"
                label="Amount"
                id="amount"
                margin="dense"
                variant="outlined"
                value={this.state.editedCategory.amount}
                onChange={this.inputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Button
                    onClick={() =>
                      this.props.editCategory(
                        this.state.editedCategory as Category
                      )
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(EditCategory);
