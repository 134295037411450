import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  Button,
} from "@material-ui/core";
import Transaction from "../Domain/Transaction";

export interface IImportTransactionOverview {
  store?: MasterStore;
  classes: any;
}

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    width: "100%",
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
const ImportTransactionOverview: React.FC<IImportTransactionOverview> = (
  props
) => {
  const [bulkEdit, setBulkEdit] = useState(false);
  const [bulkEditList, setBulkEditList] = useState([] as Transaction[]);
  const [showEditTransactionModal, setShowEditTransactionModal] = useState(
    false
  );
  const [changedCategory, setChangedCategory] = useState("");
  const [disableCategory, setDisableCategory] = useState(false);
  const [oneTransactionEdit, setOneTransactionEdit] = useState(
    new Transaction()
  );
  const [unprocessedTransactions, setUnprocessedTransactions] = useState(
    [] as Transaction[]
  );

  useEffect(() => {
    props.store!.authenticationStore.getAuthenticatedUser().then((user) => {
      if (user) {
        props
          .store!.budgetStore.getUnprocessedTransactions(user.familyId)
          .then((transactionList) => {
            setUnprocessedTransactions(transactionList);
          });
        props.store!.budgetStore.getBudgetsByFamilyId(user.familyId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const process = () => {
    var newList = unprocessedTransactions;
    if (oneTransactionEdit.Id !== "") {
      props.store!.budgetStore.updateUnprocessedTransaction(
        oneTransactionEdit.Id,
        changedCategory,
        disableCategory
      );
      var index = unprocessedTransactions.indexOf(oneTransactionEdit);
      newList.splice(index, 1);
      setOneTransactionEdit(new Transaction());
    } else {
      bulkEditList.forEach((t) => {
        props.store!.budgetStore.updateUnprocessedTransaction(
          t.Id,
          changedCategory,
          disableCategory
        );
        var index = unprocessedTransactions.indexOf(t);
        newList.splice(index, 1);
      });

      setUnprocessedTransactions(newList);
      setBulkEditList([] as Transaction[]);
      setBulkEdit(false);
    }
    setUnprocessedTransactions(newList);
    setDisableCategory(false);
    setShowEditTransactionModal(false);
  };

  const inputChanged = (event: any) => {
    if (event.target.name === "categoryId") {
      setChangedCategory(event.target.value);
    }
  };

  const addTransactionToBulkList = (transaction: Transaction) => {
    var list = bulkEditList;
    list.push(transaction);
    setBulkEditList(list);
  };

  const removeTransactionFromBulkEditList = (transaction: Transaction) => {
    var index = bulkEditList.findIndex((tran) => tran.Id === transaction.Id);
    var list = bulkEditList;
    list.splice(index, 1);
    setBulkEditList(list);
  };

  return (
    <React.Fragment>
      <Modal
        open={showEditTransactionModal}
        onClose={() => {
          setShowEditTransactionModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={props.classes.paper}
        >
          <Grid item>
            <InputLabel id="categoryId">Category</InputLabel>
            <Select
              labelId="categoryId"
              id="categorySelectId"
              name="categoryId"
              value={changedCategory}
              onChange={inputChanged}
              className={props.classes.selectBox}
              disabled={disableCategory}
            >
              {props.store!.budgetStore.currentBudget.categories.map(
                (category, i) => {
                  return (
                    <MenuItem key={"option" + i} value={category.Id}>
                      {category.name}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDisableCategory(true);
                process();
              }}
            >
              Ignore
            </Button>
            <Button variant="contained" color="primary" onClick={process}>
              Save
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <Grid container className={props.classes.root}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <h2>Transactions</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {!bulkEdit && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setBulkEdit(true)}
            >
              Bulk Edit
            </Button>
          )}
          {bulkEdit && (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowEditTransactionModal(true)}
              >
                Edit Checked
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setBulkEdit(false);
                  setBulkEditList([] as Transaction[]);
                }}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
          {unprocessedTransactions && unprocessedTransactions.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {unprocessedTransactions.map((trans) => {
                    return (
                      <TableRow
                        key={trans.Id}
                        onClick={(event: any) => {
                          if (!bulkEdit) {
                            setShowEditTransactionModal(true);
                            setOneTransactionEdit(trans);
                          }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>
                            {trans.description.replace(
                              "Point Of Sale Withdrawal /",
                              ""
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{trans.amount}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            {trans.date.toLocaleDateString()}
                          </Typography>
                        </TableCell>
                        {bulkEdit && (
                          <React.Fragment>
                            <TableCell component="th" scope="row">
                              <Checkbox
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    addTransactionToBulkList(trans);
                                  } else {
                                    removeTransactionFromBulkEditList(trans);
                                  }
                                }}
                                className="checkboxes"
                                name="checkedB"
                                color="primary"
                              />
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default inject("store")(
  observer(withStyles(styles)(ImportTransactionOverview))
);
