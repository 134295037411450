import React, { useCallback } from "react";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import { Grid, Typography, Button } from "@material-ui/core";
import { usePlaidLink } from "react-plaid-link";
import Account from "../Domain/Account";

export interface IPlaidConnection {
  store?: MasterStore;
  userId: string;
  familyId: string;
  buttonLabel: string;
  message: string;
  setPlaidAccountList(accounts: Account[]): void;
}

const PlaidConnection: React.FC<IPlaidConnection> = (props) => {
  const [linkToken, setLinkToken] = React.useState("");
  React.useEffect(() => {
    props
      .store!.plaidAccessStore.getNewLinkToken(props.userId)
      .then((response) => setLinkToken(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback(
    (token: any, metadata: any) => {
      props.store!.plaidAccessStore.exhangePublicTokenAndSave(
        token,
        props.familyId
      );
      props.store!.accountStore.deleteAccountsByFamily(props.familyId);
      props
        .store!.plaidAccessStore.getAccounts(props.familyId)
        .then((accounts: Account[]) => {
          props.store!.accountStore.addPlaidAccounts(accounts);
          props.setPlaidAccountList(accounts);
        });
    },
    [props]
  );

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={8}>
          <Typography>{props.message}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => open()}
            disabled={!ready}
          >
            {props.buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default inject("store")(observer(PlaidConnection));
