import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import { Modal, Grid, Button, Dialog, DialogTitle } from "@material-ui/core";
import AddBudget from "./AddBudget";
import Budget from "../Domain/Budget";
import EnhancedTable from "./EnhancedTable/EnhancedTable";
import IEnhancedTableHeadCell from "../Domain/IEnhancedTableHeadCell";
import IEnhancedTableRow, { Data } from "../Domain/IEnhancedTableRow";
import { Redirect } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

function createData(
  budgetMonth: string,
  budgetYear: number,
  budgetStartingAmount: number,
  budgetRemainingBalance: number,
  numberOfTransactions: number,
  xsMode: boolean
): Data[] {
  return [
    { value: budgetMonth, currency: false, display: true },
    { value: budgetYear, currency: false, display: true },
    { value: budgetStartingAmount, currency: true, display: true },
    {
      value: budgetRemainingBalance,
      currency: true,
      display: xsMode ? false : true,
    },
    {
      value: numberOfTransactions,
      currency: false,
      display: xsMode ? false : true,
    },
  ];
}

export interface IBudgetsProps {
  store?: MasterStore;
  width?: Breakpoint;
}

const Budgets: React.FC<IBudgetsProps> = (props) => {
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentDeleteBudgetId, setCurrentDeleteBudgetId] = useState("");
  const [currentEditRedirect, setCurrentEditRedirect] = useState("");

  const headCells: IEnhancedTableHeadCell[] = [
    {
      id: "month",
      numeric: false,
      label: "Month",
      display: true,
    },
    {
      id: "year",
      numeric: true,
      label: "Year",
      display: true,
    },
    {
      id: "initialBudgetTotal",
      numeric: true,
      label: "Initial Budget Total",
      display: true,
    },
    {
      id: "remainingBalance",
      numeric: true,
      label: "Remaining Balance",
      display: props.width !== "xs",
    },
    {
      id: "transactionsCount",
      numeric: true,
      label: "Number of Transactions",
      display: props.width !== "xs",
    },
  ];

  useEffect(() => {
    props.store!.authenticationStore.getAuthenticatedUser().then((user) => {
      if (user) {
        props.store?.budgetStore.getBudgetsByFamilyId(user.familyId);
      }
    });
  }, [props.store]);

  const addBudgetClicked = () => {
    setShowNewBudgetModal(true);
  };

  const addBudget = (budget: Budget, copyDate: false | Date) => {
    if (copyDate) {
      props.store?.budgetStore.addBudgetFromCopy(budget, copyDate);
    } else {
      props.store?.budgetStore.addBudgetFromBlank(budget);
    }
    setShowNewBudgetModal(false);
  };

  const deleteButtonClicked = (budgetId: string) => {
    setCurrentDeleteBudgetId(budgetId);
    setShowDeleteConfirmation(true);
  };

  const deleteConfirmClicked = () => {
    props.store?.budgetStore.deleteBudget(currentDeleteBudgetId);
    setCurrentDeleteBudgetId("");
    setShowDeleteConfirmation(false);
  };

  const editButtonClicked = (budgetId: string) => {
    setCurrentEditRedirect(budgetId);
  };

  const createRow = (budget: Budget): IEnhancedTableRow => {
    return {
      name: budget.Id,
      values: createData(
        Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(budget.date),
        budget.date.getFullYear(),
        props.store!.budgetStore.getBudgetCategoriesSum(budget.Id),
        props.store!.budgetStore.getBudgetAmountLeft(budget.Id),
        props.store!.budgetStore.getNumberOfTransactions(budget.Id),
        props.width === "xs"
      ),
      editButton: true,
      editButtonClicked: editButtonClicked,
      deleteButton: true,
      deleteButtonClicked: deleteButtonClicked,
    } as IEnhancedTableRow;
  };

  const getRowsForTable = (): IEnhancedTableRow[] => {
    return props
      .store!.budgetStore.allBudgetsForUser?.slice()
      .sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        } else if (a.date > b.date) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((budget) => {
        return createRow(budget) as IEnhancedTableRow;
      });
  };

  if (currentEditRedirect !== "") {
    return <Redirect to={"/BudgetEditor/" + currentEditRedirect} />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          onClose={() => setShowDeleteConfirmation(false)}
          aria-labelledby="simple-dialog-title"
          open={showDeleteConfirmation}
        >
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete this entire budget, its categories
            and its transactions????
          </DialogTitle>
          <Button onClick={deleteConfirmClicked}>
            Yes, I totally know what I am doing! JEEZ MOM!
          </Button>
        </Dialog>
        <Modal
          open={showNewBudgetModal}
          onClose={() => {
            setShowNewBudgetModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <AddBudget
            addBudget={addBudget}
            previousBudgetDates={props.store!.budgetStore?.getAllPreviousBudgetDates()}
          />
        </Modal>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={8} xl={3} style={{ textAlign: "center" }}>
                <h2>Manage Budgets</h2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <EnhancedTable
                  title="Budgets"
                  rows={getRowsForTable()}
                  headCells={headCells}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={addBudgetClicked}
                >
                  Add Budget
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

export default inject("store")(observer(withWidth()(Budgets)));
