import { observable, action } from "mobx";
import FamilyMember from "../Domain/FamilyMember";
import { DynamoDBClient } from "../Clients/DynamoDBClient";
import { Guid } from "guid-typescript";
import { FamilyMemberRole } from "../Domain/FamilyMemberRole";
import { InvitationStatus } from "../Domain/InvitationStatus";
import { EmailClient } from "../Clients/EmailClient";
import "mobx-react-lite/batchingForReactDom";
import { CognitoClient } from "../Clients/CognitoClient";

export default class FamilyManagementStore {
  @observable public familyMemberList: FamilyMember[] = [];
  private dbClient: DynamoDBClient = new DynamoDBClient("");
  private emailClient: EmailClient = new EmailClient();
  private cognitoClient: CognitoClient = new CognitoClient();

  @action
  public getFamilyMembers(familyId: string) {
    if (this.familyMemberList.length === 0 || familyId !== "") {
      this.dbClient.getFamilyMembers(familyId).then((members) => {
        this.familyMemberList = this.familyMemberList.concat(members);
      });
    }
  }

  @action
  addFamilyMember(familyId: string, newMemberEmail: string) {
    this.emailClient.sendNewFamilyMemberInvitation(newMemberEmail, familyId);

    let newMember = new FamilyMember();
    newMember.Id = Guid.create().toString();
    newMember.emailAddress = newMemberEmail;
    newMember.familyId = familyId;
    newMember.role = FamilyMemberRole.Standard;
    newMember.status = InvitationStatus.Sent;

    this.dbClient.putFamilyMember(newMember);
    this.familyMemberList?.push(newMember);
  }

  @action
  updateMemberFamilyId(familyId: string, email: string) {
    this.cognitoClient.updateUserFamilyId(familyId, email);
    this.dbClient.getMemberByEmail(email).then((member) => {
      this.dbClient.updateFamilyMemberInvitationStatus(
        InvitationStatus.Accepted,
        member!.Id
      );
    });
  }

  @action
  removeFamilyMember(familyId: string, email: string, memberId: string) {
    var newFamilyId = Guid.create().toString();
    this.dbClient.updateFamilyMemberId(newFamilyId, memberId);
    this.cognitoClient.updateUserFamilyId(newFamilyId, email);
    const index = this.familyMemberList.findIndex(
      (member) => member.emailAddress === email
    );
    this.familyMemberList = this.familyMemberList.splice(index, 1);
  }
}
