import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import {
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import User from "../Domain/User";
import Account from "../Domain/Account";
import PlaidConnection from "./PlaidConnection";

export interface IAccountsOverview {
  store?: MasterStore;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AccountsOverview: React.FC<IAccountsOverview> = (props) => {
  const [currentUser, setCurrentUser] = useState(new User());
  const [accountList, setAccountList] = useState([] as Account[]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndexChange, setCurrentIndexChange] = useState(-1);
  const [isLoadingConnectionChange, setIsLoadingConnectionChange] = useState(
    false
  );

  useEffect(() => {
    props.store!.authenticationStore.getAuthenticatedUser().then((user) => {
      if (user) {
        setCurrentUser(user);
        props.store?.accountStore
          .getAccountsByFamilyId(user.familyId)
          .then((accounts) => {
            setAccountList(accounts);
          });
        props.store!.plaidItemStore.getPlaidItems(user.familyId).then(() => {
          setIsLoading(false);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectOrDisconnectAccount = (account: Account, index: number) => {
    setCurrentIndexChange(index);
    setIsLoadingConnectionChange(true);
    props.store?.accountStore
      .changeConnectionForAccount(
        account.account_id,
        !account.isConnected,
        account.familyId
      )
      .then(() => {
        var newList = accountList;
        var index = newList.indexOf(account);
        newList[index].isConnected = !account.isConnected;
        setAccountList(newList);
        setIsLoadingConnectionChange(false);
        setCurrentIndexChange(-1);
      });
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      {isLoading && (
        <Grid container justify="center" style={{ paddingTop: "2em" }}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <h2>Accounts</h2>
              </Grid>
            </Grid>
          </Grid>
          {props.store!.plaidItemStore.hasExpired && currentUser.id && (
            <Grid item xs={12}>
              <PlaidConnection
                userId={currentUser.id}
                familyId={currentUser.familyId}
                buttonLabel="Reconnect Bank Access"
                message="Your banking access has expired please click here to refresh"
                setPlaidAccountList={(plaidAccountList) =>
                  setAccountList(plaidAccountList)
                }
              />
            </Grid>
          )}
          {props.store!.plaidItemStore.noLinksFound && currentUser.id && (
            <Grid item xs={12}>
              <PlaidConnection
                userId={currentUser.id}
                familyId={currentUser.familyId}
                buttonLabel="Connect Bank Access"
                message="Click here to connect your bank accounts to Budge-It!"
                setPlaidAccountList={(plaidAccountList) => {
                  setAccountList(plaidAccountList);
                }}
              />
            </Grid>
          )}
          <Grid item xs={8}>
            {!props.store!.plaidItemStore.hasExpired &&
              !props.store!.plaidItemStore.noLinksFound &&
              accountList &&
              accountList.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      {accountList.map((account, i) => {
                        return (
                          <TableRow key={account.account_id}>
                            <TableCell component="th" scope="row">
                              <Typography>{account.name}</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography>
                                {account.availableBalance}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {currentIndexChange !== i && (
                                <Switch
                                  checked={!!account.isConnected}
                                  onChange={() =>
                                    connectOrDisconnectAccount(account, i)
                                  }
                                  name="connect"
                                />
                              )}
                              {isLoadingConnectionChange &&
                                currentIndexChange === i && (
                                  <CircularProgress />
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default inject("store")(observer(AccountsOverview));
