import Category from "./Category";
import { observable, IObservableArray } from "mobx";

export default class CategoryGroup {
  Id: string = "";
  name: string = "";
  budgetId: string = "";
  precedent: number = 0;
  categories: IObservableArray<Category> = observable<Category>([]);
}
