import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Amplify from "aws-amplify";
import FamilyManagement from "./Components/FamilyManagement";
import Authentication from "./Components/Authentication";
import { Provider } from "mobx-react";
import MasterStore from "./Stores/MasterStore";
import UserInvitation from "./Components/UserInvitation";
import AWS from "aws-sdk";
import { createBrowserHistory } from "history";
import Navbar from "./Components/Navbar/Navbar";
import BudgetOverview from "./Components/BudgetOverview";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import BudgetEditor from "./Components/BudgetEditor";
import AccountsOverview from "./Components/AccountsOverview";
import ImportTransactionOverview from "./Components/ImportTransactionOverview";
import Budgets from "./Components/Budgets";

const theme = createMuiTheme({});

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_9b9tqobtU",
    userPoolWebClientId: "10bg1bk4ppv17a9gsho9ihddq8",
  },
});

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAQTZ3JL2SOYKRP4EU",
  secretAccessKey: "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",
});

function App() {
  const history = createBrowserHistory();

  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const masterStore = new MasterStore();
  return (
    <Provider store={masterStore} className="App">
      <div className="App-header">
        <Router>
          <ThemeProvider theme={theme}>
            <Navbar />
            <Switch>
              <Route exact path="/">
                <Authentication>
                  <BudgetOverview />
                </Authentication>
              </Route>
              <Route
                path="/UserInvitation/:familyId/:email"
                component={UserInvitation}
              />
              <Route path="/FamilyManagement">
                <Authentication>
                  <FamilyManagement />
                </Authentication>
              </Route>
              <Route path="/Accounts">
                <Authentication>
                  <AccountsOverview />
                </Authentication>
              </Route>
              <Route path="/Transactions">
                <Authentication>
                  <ImportTransactionOverview />
                </Authentication>
              </Route>
              <Route path="/Budgets">
                <Authentication>
                  <Budgets />
                </Authentication>
              </Route>
              <Authentication>
                <Route
                  path="/BudgetEditor/:budgetId"
                  component={BudgetEditor}
                />
              </Authentication>
            </Switch>
          </ThemeProvider>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
