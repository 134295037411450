import { ClientBase } from "./ClientBase";

export class PlaidClient extends ClientBase {
  async getLinkToken(userId: string): Promise<string> {
    var returnToken = "";
    await this.Setup();
    await fetch(
      this.clientConnectionBase + "linktoken?userId=" + userId,
      this.getOptionsForClient("GET", null)
    )
      .then((res) => res.json())
      .then((response) => {
        returnToken = response;
      })
      .catch((error) => {
        console.log(error);
      });

    return returnToken;
  }

  async exhangePublicTokenAndSave(
    publicToken: string,
    familyId: string
  ): Promise<void> {
    await this.Setup();
    var body = {
      public_token: publicToken,
      familyId: familyId,
    };
    await fetch(
      this.clientConnectionBase + "privatetoken",
      this.getOptionsForClient("POST", body)
    ).catch((error) => {
      console.log(error);
    });
  }

  async getAccountsList(userId: string): Promise<any[]> {
    var returnArray = [] as any[];
    await this.Setup();
    await fetch(
      this.clientConnectionBase + "account?familyId=" + userId,
      this.getOptionsForClient("GET", null)
    )
      .then((res) => res.json())
      .then((accts) => {
        returnArray = accts;
      })
      .catch((error) => {
        console.log(error);
      });

    return returnArray;
  }
}
