import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Input,
} from "@material-ui/core";
import React, { Component } from "react";
import Transaction from "../Domain/Transaction";
import Category from "../Domain/Category";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150%",
    },
  },
});

export interface IAddTransaction {
  categories: Category[];
  defaultCategoryId: string;
  addTransaction: (transaction: Transaction) => void;
  classes: any;
}

class AddTransaction extends Component<IAddTransaction> {
  state = {
    newTransaction: {
      ...new Transaction(),
      categoryId: this.props.defaultCategoryId,
    },
  };

  inputChange = (event: any) => {
    this.setState({
      newTransaction: {
        ...this.state.newTransaction,
        [event.target.name]: event.target.value,
      } as Transaction,
    });
  };

  inputDateChange = (date: any) => {
    this.setState({
      newTransaction: {
        ...this.state.newTransaction,
        date: date,
      } as Transaction,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Paper
          tabIndex={-1}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={this.props.classes.paper}
        >
          <h2>Add Transaction</h2>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={1}
          >
            <form>
              <Grid item>
                <TextField
                  name="description"
                  label="Description"
                  id="description"
                  margin="dense"
                  variant="outlined"
                  value={this.state.newTransaction.description}
                  onChange={this.inputChange}
                  autoComplete="none"
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <Input
                  name="amount"
                  id="amount"
                  margin="dense"
                  value={
                    this.state.newTransaction.amount === 0
                      ? ""
                      : this.state.newTransaction.amount
                  }
                  onChange={this.inputChange}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="date"
                    id="date"
                    variant="inline"
                    label="Date"
                    value={this.state.newTransaction.date}
                    onChange={(date) => this.inputDateChange(date)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ paddingTop: "1em" }}>
                <InputLabel id="categoryId">Category</InputLabel>
                <Select
                  labelId="categoryId"
                  id="categorySelectId"
                  name="categoryId"
                  value={this.state.newTransaction.categoryId}
                  onChange={this.inputChange}
                  className={this.props.classes.selectBox}
                >
                  {this.props.categories.map((category, i) => {
                    return (
                      <MenuItem key={"option" + i} value={category.Id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    xs={12}
                    className={this.props.classes.justForTesting}
                  >
                    <Button
                      onClick={() =>
                        this.props.addTransaction(this.state.newTransaction)
                      }
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(AddTransaction);
