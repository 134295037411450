import AuthenticationStore from "./AuthenticationStore";
import FamilyManagementStore from "./FamilyMangementStore";
import BudgetStore from "./BudgetStore";
import AccountStore from "./AccountStore";
import PlaidAccessStore from "./PlaidAccessStore";
import PlaidItemStore from "./PlaidItemStore";

export default class MasterStore {
  public authenticationStore = new AuthenticationStore();
  public familyManagementStore = new FamilyManagementStore();
  public budgetStore = new BudgetStore();
  public accountStore = new AccountStore();
  public plaidAccessStore = new PlaidAccessStore();
  public plaidItemStore = new PlaidItemStore();
}
