import CategoryGroup from "./CategoryGroup";
import Category from "./Category";
import { observable, IObservableArray } from "mobx";

export default class Budget {
  Id: string = "";
  date: Date = new Date();
  familyId: string = "";
  categories: IObservableArray<Category> = observable<Category>([]);
  categoryGroups: IObservableArray<CategoryGroup> = observable<CategoryGroup>(
    []
  );
}
