import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { Component } from "react";
import Budget from "../Domain/Budget";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectBox: {
    width: 400,
  },
});

export interface IAddBudget {
  addBudget: (budget: Budget, dateToCopyFrom: false | Date) => void;
  previousBudgetDates: Date[];
  classes: any;
}

class AddBudget extends Component<IAddBudget> {
  state = {
    newBudget: {
      ...new Budget(),
    },
    option: "none",
    dateToCopy: false as false | Date,
    invalidDate: false,
  };

  handleRadioChange = (event: any) => {
    this.setState({ option: event.target.value });
  };

  handleSelectChange = (event: any) => {
    this.setState({ dateToCopy: event.target.value });
  };

  inputChange = (event: any) => {
    this.setState({
      newBudget: {
        ...this.state.newBudget,
        [event.target.name]: event.target.value,
      } as Budget,
    });
  };

  inputDateChange = (date: any) => {
    if (this.checkListForMonthAndYear(date, this.props.previousBudgetDates)) {
      this.setState({ invalidDate: true });
    } else {
      this.setState({ invalidDate: false });
    }
    this.setState({
      newBudget: {
        ...this.state.newBudget,
        date: date,
      } as Budget,
    });
  };

  checkListForMonthAndYear = (date: Date, dateList: Date[]): boolean => {
    if (
      dateList.findIndex(
        (dateItem) =>
          dateItem.getFullYear() === date.getFullYear() &&
          dateItem.getMonth() === date.getMonth()
      ) > -1
    ) {
      return true;
    }
    return false;
  };

  saveButtonClicked = () => {
    let date = false as false | Date;

    if (this.state.dateToCopy) {
      date = new Date(this.state.dateToCopy);
    }

    this.props.addBudget(this.state.newBudget as Budget, date);
  };

  render() {
    if (
      this.checkListForMonthAndYear(
        this.state.newBudget.date,
        this.props.previousBudgetDates
      )
    ) {
      this.setState({
        ...this.state.newBudget,
        date: new Date(
          this.state.newBudget.date.setMonth(
            this.state.newBudget.date.getMonth() + 1
          )
        ),
      });
    }
    return (
      <React.Fragment>
        <Paper
          tabIndex={-1}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={this.props.classes.paper}
        >
          <h2>Add Budget</h2>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  name="date"
                  id="date"
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  label="Date"
                  helperText={
                    this.state.invalidDate
                      ? "This budget date is already in use"
                      : "Month and Year"
                  }
                  value={this.state.newBudget.date}
                  onChange={(date) => this.inputDateChange(date)}
                  error={this.state.invalidDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="blankFormControl"
                    control={
                      <Radio
                        checked={this.state.option === "blank"}
                        onChange={this.handleRadioChange}
                        value="blank"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "Start with a blank form" }}
                      />
                    }
                    label="Start with a blank budget"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="copyFormControl"
                    control={
                      <Radio
                        checked={this.state.option === "copy"}
                        onChange={this.handleRadioChange}
                        value="copy"
                        name="radio-button-demo"
                        inputProps={{
                          "aria-label": "Make a copy from a previous budget",
                        }}
                      />
                    }
                    label="Copy a budget from a previous month"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              {this.state.option === "copy" && (
                <Grid
                  container
                  style={{ border: "1px black solid", padding: "1em" }}
                >
                  <Grid item>
                    <InputLabel id="categoryId">Previous Budgets</InputLabel>
                    <Select
                      labelId="previousBudgetDateLabel"
                      id="previousBudgetDate"
                      name="previousBudgetDate"
                      value={this.state.dateToCopy}
                      onChange={this.handleSelectChange}
                      className={this.props.classes.selectBox}
                    >
                      {this.props.previousBudgetDates.map((budgetDate, i) => {
                        return (
                          <MenuItem
                            key={"option" + i}
                            value={budgetDate.toLocaleDateString()}
                          >
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                            }).format(budgetDate)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={this.saveButtonClicked}
                    disabled={
                      this.state.invalidDate ||
                      this.state.option === "none" ||
                      (this.state.option === "copy" && !this.state.dateToCopy)
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(AddBudget);
