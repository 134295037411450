import React from "react";
import { Typography, Grid } from "@material-ui/core";

export interface ICategoryGroupHeader {
  children: React.ReactNode;
  groupName: string;
  displayHeader: boolean;
}

const CategoryGroupHeader: React.FC<ICategoryGroupHeader> = (props) => {
  return (
    <React.Fragment>
      {props.displayHeader && (
        <Grid
          container
          style={{
            border: "1px solid #8c95a6",
            padding: "20px",
            borderRadius: "25px",
          }}
        >
          <Grid item>
            <Typography
              style={{
                marginTop: "-2em",
                marginLeft: "20px",
                backgroundColor: "#282c34",
              }}
            >
              {props.groupName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default CategoryGroupHeader;
