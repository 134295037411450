import { DynamoDBClient } from "../Clients/DynamoDBClient";
import "mobx-react-lite/batchingForReactDom";
import { action, computed, observable } from "mobx";
import PlaidItem from "../Domain/PlaidItem";

export default class PlaidItemStore {
  private dbClient: DynamoDBClient = new DynamoDBClient(
    "https://pidqxp680j.execute-api.us-east-1.amazonaws.com/v1/"
  );
  @observable public plaidItems = [] as PlaidItem[];

  @action
  public async getPlaidItems(familyId: string): Promise<PlaidItem[]> {
    await this.dbClient.getPlaidItems(familyId).then((items) => {
      this.plaidItems = items;
    });

    return this.plaidItems;
  }

  @computed
  public get hasExpired() {
    if (this.plaidItems.find((p) => p.isValid === false)) {
      //delete accounts
      return true;
    } else {
      return false;
    }
  }

  @computed
  public get noLinksFound() {
    return this.plaidItems.length === 0;
  }
}
