import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import MasterStore from "../Stores/MasterStore";
import { FamilyMemberRole } from "../Domain/FamilyMemberRole";
import { InvitationStatus } from "../Domain/InvitationStatus";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles({
  inputBox: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    display: "inline",
  },
  inputButton: {
    display: "inline",
  },
  inputFont: {
    color: "white",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGroup: {
    border: "1px solid red",
  },
});

export interface IFamilyManagementProps {
  store?: MasterStore;
}

const FamilyManagement: React.FC<IFamilyManagementProps> = (
  props: IFamilyManagementProps
) => {
  const [familyId, setFamilyId] = useState("");
  const [newMember, setNewMember] = useState("");
  const classes = useStyles();
  const famStore = props.store?.familyManagementStore!;

  useEffect(() => {
    props.store!.authenticationStore.getAuthenticatedUser().then((user) => {
      if (user) {
        setFamilyId(user.familyId);
        famStore.getFamilyMembers(user.familyId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFamilyMember = () => {
    famStore.addFamilyMember(familyId, newMember);
  };

  const removeFamilyMember = (email: string, memberId: string) => {
    famStore.removeFamilyMember(familyId, email, memberId);
  };

  const inputChange = (event: any) => {
    setNewMember(event.target.value);
  };

  return (
    <Grid container className={classes.root}>
      {famStore.familyMemberList!.length !== 0 && (
        <React.Fragment>
          <h2>Family Management</h2>
          <h5>Enter an email address to add a new member</h5>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={2}>
              <TextField
                id="margin-dense"
                margin="dense"
                className={classes.inputBox}
                variant="outlined"
                value={newMember}
                onChange={inputChange}
                InputProps={{
                  className: classes.inputFont,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={addFamilyMember}
                className={classes.inputButton}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {famStore.familyMemberList!.length === 1 && (
        <Typography style={{ paddingTop: "2em" }}>
          You are the only member of your family
        </Typography>
      )}
      {famStore.familyMemberList!.length > 1 && (
        <React.Fragment>
          <h5>Member List</h5>
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Invitation Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {famStore.familyMemberList!.map((member) => {
                    return (
                      <TableRow key={member.emailAddress}>
                        <TableCell component="th" scope="row">
                          {member.emailAddress}
                        </TableCell>
                        <TableCell align="right">
                          {FamilyMemberRole[member.role]}
                        </TableCell>
                        <TableCell align="right">
                          {InvitationStatus[member.status]}
                        </TableCell>
                        <TableCell align="right">
                          <RemoveCircleIcon
                            onClick={() =>
                              removeFamilyMember(member.emailAddress, member.Id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default inject("store")(observer(FamilyManagement));
