import AuthenticationStore from "../Stores/AuthenticationStore";

export default interface IClientBase {}

export class ClientBase implements IClientBase {
  constructor(baseUrl: string) {
    this.clientConnectionBase = baseUrl;
  }
  public clientConnectionBase: string;
  private idToken: string = "";
  private accessToken: string = "";

  public setConnectionInfo(idToken: string, accessToken: string) {
    this.idToken = idToken;
    this.accessToken = accessToken;
  }

  public async Setup() {
    var authenticationStore = new AuthenticationStore();
    await authenticationStore
      .getAuthenticatedUser()
      .then((user) => {
        if (user) {
          this.setConnectionInfo(user.idToken, user.accessToken);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public getOptionsForClient = (method: string, body: any): RequestInit => {
    return {
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        Authorization: this.idToken,
      },
    } as RequestInit;
  };

  public getOptionsForClientWithoutAuth = (
    method: string,
    body: any
  ): RequestInit => {
    return {
      method: method,
      body: body ? JSON.stringify(body) : null,
    } as RequestInit;
  };

  public getOptionsForClientWithAccessToken = (
    method: string,
    body: any
  ): RequestInit => {
    return {
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        Authorization: this.idToken,
        accessToken: this.accessToken,
      },
    } as RequestInit;
  };

  public getOptionsForS3Upload = (file: File): RequestInit => {
    return {
      method: "PUT",
      body: file,
      headers: {
        "x-amz-acl": "private",
        "Content-Type": file.type,
      },
    } as RequestInit;
  };
}
