import { PlaidClient } from "../Clients/PlaidClient";
import Account from "../Domain/Account";

export default class PlaidAccessStore {
  client = new PlaidClient(
    "https://pidqxp680j.execute-api.us-east-1.amazonaws.com/v1/plaid/"
  );

  public getNewLinkToken = async (userId: string): Promise<string> => {
    var publicToken = "";

    await this.client
      .getLinkToken(userId)
      .then((token) => {
        publicToken = token;
      })
      .catch((err) => {
        console.log(err);
        return publicToken;
      });
    return publicToken;
  };

  public exhangePublicTokenAndSave = async (
    publicToken: string,
    familyId: string
  ): Promise<void> => {
    await this.client
      .exhangePublicTokenAndSave(publicToken, familyId)
      .catch((err) => console.log(err));
  };

  public getAccounts = async (familyId: string): Promise<Account[]> => {
    var accountsArray = [] as Account[];
    await this.client.getAccountsList(familyId).then((accounts) => {
      accountsArray = accounts.map((acct: any) => acct as Account);
    });
    return accountsArray;
  };
}
