import { observable, IObservableArray } from "mobx";
import Transaction from "./Transaction";

export default class Category {
  public Id: string = "";
  public budgetId: string = "";
  public name: string = "";
  public amount: number = 0;
  public transactions: IObservableArray<Transaction> = observable<Transaction>(
    []
  );
  public categoryGroupId: string = "";
}
